module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-105970366-2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Brijesh Shah","short_name":"Brijesh Shah","description":"Brijesh's tech blog. It mainly deals with system design and integration of different technologies.","start_url":"/","lang":"ko","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"src/images/icon.png","legacy":false,"include_favicon":false,"cache_busting_mode":"query","theme_color_in_head":true,"cacheDigest":"4b287676c17549d67d4cef802131751c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.ts"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
